<template>
  <div class="wechatAccount">
    <div class="prompt">
      {{$t('wechatAccount.prompt')}}
    </div>
    <div class="wechatAccountContainer">
      <template v-if="!isAuthorization">
        <img src="../../assets/images/unauthorized.png">
        <div class="unauthorizedTip">
          {{$t('wechatAccount.unAuth')}}
        </div>
        <div class="tips">
          <span>{{$t('wechatAccount.authTip1')}}</span>
          <div class="authorization" @click="authorization">
            {{$t('wechatAccount.btnText')}}
          </div>
        </div>
      </template>
      <template v-else>
        <img src="../../assets/images/authorize.png">
        <div class="authorizedTip">
          {{$t('wechatAccount.authSuccess')}}：{{bindDetail.agentName}}
        </div>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  name: "basicInformation",
  data(){
    return{
      isAuthorization:false,
      loop:null
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    authorization(){
      let that = this
      let currentUrl = window.location.href + '&isAuthorization=true';
      if (currentUrl.indexOf("tabIndex") == -1) {
        currentUrl += "?tabIndex=bot-access";
      }
      if (currentUrl.indexOf("bindType") == -1) {
        currentUrl += "&bindType=mp";
      }
      console.log('currentUrl',currentUrl)
      this.FetchGet(that.requestUrl.wechatBind.newWechatBind, {
        bindType: 0,
        currentUrl: currentUrl,
        authType: 1, //1:公众号 2：小程序 3：公众号和小程序
      }).then((res) => {
        if (res.code === "0") {
          if (res.data) {
            location.href = res.data;
            // let w = window.open(
            //     res.data,
            //     "newwindow",
            //     "height=500, width=800, top=200, left=400, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
            // );
            // that.loop = setInterval(function () {
            //   if (w != null && w.closed) {
            //     clearInterval(that.loop);
            //     //do something 在这里执行回调
            //     that.isAuthorization = true;
            //     that.$emit('authorizationSuccess')
            //   }
            // }, 800);
          }
        } else {
          this.$message.error("绑定失败，请检查填写内容");
        }
      });
    }
  },
  mounted() {
    var element = document.getElementsByTagName('body');
    if (element && element.length > 0){
      element[0].style.backgroundColor = '#ffffff';
    }
    var appEle = document.getElementById('app');
    appEle.style.height = "100%";
    let isAuthorization = this.$route.query.isAuthorization;
    if (isAuthorization){
      this.isAuthorization = true;
      // 发送消息到父页面
      window.parent.postMessage('authorizationSuccess', '*');
    }
    let locale = this.$route.query.locale;
    if (locale){
      this.$i18n.locale = locale;
    }
  }
};
</script>

<style scoped lang="less">
.wechatAccount{
  height: 100%;
  .prompt{
    background: #FFF8F4;
    border-radius: 5px;
    border: 1px solid #FFD9C5;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FF9555;
  }
  .wechatAccountContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    .unauthorizedTip{
      color: #616161;
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
    }
    .authorizedTip{
      color: #616161;
      font-size: 18px;
      font-weight: bold;
      margin-top: 20px;
    }
    .tips{
      display: flex;
      align-items: center;
      color: #616161;
      .authorization{
        margin-left: 6px;
        cursor: pointer;
        color: #366aff;
      }
    }
  }
}
</style>